import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  ArrowLeftIcon,
  ArrowPathIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import toast, { Toaster } from "react-hot-toast";
// import AutocompleteTextArea from "../components/AutocompleteTextArea";
import CopyButton from "../components/CopyButton";
import Layout from "../components/Layout";
import Select from "react-select";
import Spinner from "../components/Spinner";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import NoteHeader from "../components/note/NoteHeader";
import { toTitleCase } from "../utils/lib";
import { focusAtom } from "jotai-optics";
import { useParams } from "react-router-dom";

import { atom, useAtom } from "jotai";

import { MIPS } from "../utils/other";

import {
  CDTSections,
  cdtSectionsData,
  scoreRanges,
  CDTRecursiveSections,
  cdtRecursiveSectionsData,
} from "../utils/cdt";

import Section from "../components/note/Section";
import TextSection from "../components/note/TextSection";
import NoteFooter from "../components/note/NoteFooter";
import { DataContext } from "../dataContext/DataContext";
// import FeedbackButtons from "../components/note/FeedbackButtons";
import CDTCalculator from "../components/CDT/Calculator";
import { useHistory } from "react-router-dom";

import RecursiveCalculator from "../components/CDT/RecursiveCalculator";
// import {
//   deepEquals,
//   toTitleCase,
//   wholeNoteToText,
//   mdmToText,
// } from "../utils/lib";

import { atomWithReset } from "jotai/utils";

const includeSectionsDefault = {
  patient_information: false,
  summary: true,
  ddx: false,
  mdm: true,
  followup: true,
  extra_notes: false,
  chief_complaint: true,
  mips_notes: false,
  cdt_heart: false,
  cdt_nihss: false,
  cdt_pecarn: false,
};

function NoteSummaryMenu({ onGenerateAgain }) {
  return (
    <div className="relative ml-auto inline-block text-left">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0">
              <span className="sr-only">Open options</span>
              •••
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-0 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="rounded-lg border-[1px] border-solid border-gray-300 p-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={onGenerateAgain}
                        className={`${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        } group flex w-full items-center px-4 py-2 text-sm`}
                      >
                        Generate again
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

// function ClearButton({ onClick }) {
//   return (
//     <button className="rounded-md text-primary" onClick={onClick}>
//       <XMarkIcon className="inline-block h-4 w-4" />{" "}
//       <span className="align-middle">Clear</span>
//     </button>
//   );
// }

const selectedMipsAtom = atom(null);
const localContentAtom = atom("");

const log = (component, action, data) => {
  console.log(`[${component}] ${action}:`, data);
};

const MipsStatement = React.memo(function MipsStatement({
  id,
  statements,
  onStatementSelect,
}) {
  const [selectedStatement, setSelectedStatement] = useState(undefined);

  useEffect(() => {
    if (typeof id === "number") setSelectedStatement(undefined);
  }, [id]);

  log("MipsStatement", "Render", {
    id,
    statementsCount: Object.keys(statements).length,
  });

  return (
    <>
      {Object.entries(statements).length > 0 && (
        <div className="overflow-hidden rounded-md bg-white shadow-md">
          {Object.values(statements).map(
            (statement) =>
              (selectedStatement?.value.name === statement.name ||
                selectedStatement === undefined) && (
                <button
                  key={statement.name}
                  onClick={() => {
                    log("MipsStatement", "Statement Click", statement);
                    if (selectedStatement?.value.name === statement.name)
                      setSelectedStatement(undefined);
                    else
                      setSelectedStatement({
                        label: statement.name,
                        value: statement,
                      });

                    if (
                      !statement.sub_statements ||
                      Object.keys(statement.sub_statements).length === 0
                    ) {
                      onStatementSelect(statement);
                    }
                  }}
                  className={classNames(
                    "flex w-full justify-between border-b border-gray-300 px-4 py-3",
                    selectedStatement?.value.name === statement.name
                      ? "bg-blue-500 text-white"
                      : "text-black"
                  )}
                >
                  <p className="text-left">{statement.name}</p>
                </button>
              )
          )}
        </div>
      )}

      {selectedStatement &&
        Object.keys(selectedStatement.value.sub_statements || {}).length >
          0 && (
          <MipsStatement
            id={selectedStatement.value.name}
            statements={selectedStatement.value.sub_statements}
            onStatementSelect={onStatementSelect}
          />
        )}
    </>
  );
});

function MipsSection({
  noteId,
  noteSlug,
  content,
  isUpdatingNote,
  mipsData,
  mipsSuccess,
  resultsKey,
}) {
  const [selectedMips, setSelectedMips] = useAtom(selectedMipsAtom);
  const [localContent, setLocalContent] = useAtom(localContentAtom);
  const [innerLoading, setInnerLoading] = useState(false);
  const [optionsKey, setOptionsKey] = useState(0);

  useEffect(() => {
    log("MipsSection", "Content Changed", { content, noteId, noteSlug });
    setLocalContent(content || "");
  }, [content, setLocalContent, noteId, noteSlug]);

  const resetOptions = () => setOptionsKey((p) => p + 1);

  const handleSaveSection = useCallback(
    (updatedContent) => {
      log("MipsSection", "Saving Section", updatedContent);
      setLocalContent(updatedContent[resultsKey]);
      setInnerLoading(false);
    },
    [setLocalContent, resultsKey]
  );

  const handleStatementSelect = useCallback(
    (statement) => {
      log("MipsSection", "Statement Selected", {
        statement,
        selectedMips,
        noteSlug,
      });
      if (noteSlug) {
        setInnerLoading(true);
        const newContent = selectedMips
          ? `#${selectedMips.id} - ${selectedMips.name}: ${statement.content}`
          : statement.content;
        handleSaveSection({
          [resultsKey]: newContent,
        });
      } else {
        console.warn(
          "MipsSection: noteSlug is undefined. Unable to save statement."
        );
        // Update localContent even without a noteSlug
        setLocalContent(
          selectedMips
            ? `#${selectedMips.id} - ${selectedMips.name}: ${statement.content}`
            : statement.content
        );
      }
    },
    [noteSlug, selectedMips, handleSaveSection, resultsKey, setLocalContent]
  );

  log("MipsSection", "Render", {
    localContent,
    innerLoading,
    isUpdatingNote,
    mipsSuccess,
  });

  return (
    <Section title="MIPS Notes" styled={false}>
      <div className="p-4">
        <MipsOptions
          key={optionsKey}
          mips={mipsSuccess ? mipsData : []}
          onStatementSelect={handleStatementSelect}
        />
      </div>
      <div className="space-y-4 rounded-b-md bg-gray-200 px-8 py-6 xl:grid xl:grid-cols-[4fr_2fr] xl:space-x-4 xl:space-y-0">
        <div>
          {innerLoading || isUpdatingNote ? (
            <div className="flex items-center justify-center">
              <Spinner size="Small" />
            </div>
          ) : (
            localContent !== undefined &&
            localContent !== null &&
            localContent.split("\n\n").map((line, i) => (
              <p
                key={i}
                className={classNames(
                  line.length < 500 ? "break-words" : "break-all",
                  "mb-1 break-words text-sm"
                )}
              >
                {line}
              </p>
            ))
          )}
        </div>
        <div className="flex items-center justify-center xl:justify-end">
          <CopyButton
            disabled={!localContent || innerLoading || isUpdatingNote}
            containerClassName="px-6 py-3 shadow-md"
            content={localContent}
          >
            Copy Results
          </CopyButton>
        </div>
      </div>
    </Section>
  );
}

function MipsOptions({ mips, onStatementSelect }) {
  const [selectedMips, setSelectedMips] = useAtom(selectedMipsAtom);
  const [enabled, setEnabled] = useState(true);
  const [shownStatements, setShownStatements] = useState(undefined);

  useEffect(() => {
    log("MipsOptions", "Selected MIPS Changed", { selectedMips, enabled });
    if (selectedMips) {
      const selectedMipsStatement = enabled
        ? selectedMips.statements["YES"]
        : selectedMips.statements["NO"];
      if (
        selectedMipsStatement &&
        Object.keys(selectedMipsStatement.sub_statements || {}).length === 0
      ) {
        onStatementSelect(selectedMipsStatement);
        setShownStatements(undefined);
      } else {
        setShownStatements(
          selectedMipsStatement && selectedMipsStatement.sub_statements
        );
      }
    }
  }, [selectedMips, enabled, onStatementSelect]);

  log("MipsOptions", "Render", {
    mipsCount: mips.length,
    enabled,
    shownStatementsExist: !!shownStatements,
  });

  return (
    <div className="flex flex-col gap-3">
      <div className="mb-4 flex items-center gap-2">
        <Select
          placeholder="Select Statement..."
          options={mips.map((mip) => ({
            label: `#${mip.id} - ${mip.name}`,
            value: mip,
          }))}
          className="min-w-[200px] max-w-[300px]"
          onChange={(selected) => {
            log("MipsOptions", "MIPS Selected", selected);
            if (selected) {
              setSelectedMips(selected.value);
            }
          }}
        />

        {selectedMips && (
          <Switch
            checked={enabled}
            onChange={(newEnabled) => {
              log("MipsOptions", "Switch Changed", {
                oldEnabled: enabled,
                newEnabled,
              });
              setEnabled(newEnabled);
            }}
            className={classNames(
              enabled ? "bg-green-600" : "bg-red-600",
              "relative inline-flex h-6 w-11 items-center rounded-full"
            )}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={classNames(
                enabled ? "translate-x-6" : "translate-x-1",
                "inline-block h-4 w-4 transform rounded-full bg-white transition"
              )}
            />
            <span
              className={classNames(
                enabled ? "-translate-x-3" : "translate-x-2",
                "inline-block transform rounded-full text-[.6em] font-bold text-white transition"
              )}
            >
              {enabled ? "YES" : "NO"}
            </span>
          </Switch>
        )}
      </div>

      {selectedMips && shownStatements && (
        <MipsStatement
          id={selectedMips.id}
          statements={shownStatements}
          onStatementSelect={onStatementSelect}
        />
      )}
    </div>
  );
}

// function MDMSection({
//   noteId,
//   noteSlug,
//   macros,
//   content,
//   summaryKey,
//   sectionProps,
//   isLoading = false,
// }) {
//   const copiableContent = mdmToText(content);
//   return (
//     <Section
//       right={
//         <>
//           <CopyButton content={copiableContent} />
//           {summaryKey && (
//             <FeedbackButtons noteId={noteId} summaryKey={summaryKey} />
//           )}
//         </>
//       }
//       {...sectionProps}
//     >
//       {isLoading ? (
//         <div className="flex items-center justify-center">
//           <Spinner />
//         </div>
//       ) : (
//         <>
//           {content.map(({ title, content: subsectionContent }, i) => (
//             <MDMSubsection
//               noteId={noteId}
//               noteSlug={noteSlug}
//               macros={macros}
//               title={title}
//               content={subsectionContent}
//               key={`${title}-${i}`}
//             />
//           ))}
//         </>
//       )}
//     </Section>
//   );
// }

// function MDMSubsection({
//   noteId,
//   noteSlug,
//   macros,
//   title,
//   content,
//   copyContent = content,
// }) {
//   const [localContent, setLocalContent] = useState(content);

//   // const handleContentChange = (newContent) => {
//   //   setLocalContent(newContent);
//   //   // Implement API call to save changes
//   //   console.log("Saving MDM changes:", {
//   //     noteSlug,
//   //     title,
//   //     content: newContent,
//   //   });
//   // };

//   return (
//     <div className="mb-4">
//       <span className="items-middle mb-2 flex">
//         <h4 className="mr-3 text-lg font-medium">{title}</h4>
//         <CopyButton content={copyContent} />
//       </span>

//       <AutocompleteTextArea
//         initialValue={localContent}
//         options={macros}
//         // onUpdate={handleContentChange}
//       />
//     </div>
//   );
// }

function Selector({ children }) {
  // Pre-compute the visibility states for all children
  const childrenArray = React.Children.toArray(children);

  const visibilityStates = useMemo(
    () =>
      childrenArray.map((child) => {
        if (child.props.shownAtom) {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isShown] = useAtom(child.props.shownAtom);
          return isShown;
        }
        return false;
      }),
    [childrenArray]
  );

  return (
    <div className="mt-5 flex-col">
      <div className="mb-1.5 flex flex-wrap gap-2">
        {childrenArray.map((child, index) => {
          if (child.props.shownAtom) {
            return (
              <SelectorButton
                key={child.props.title}
                label={child.props.title}
                shownAtom={child.props.shownAtom}
              />
            );
          }
          return null;
        })}
      </div>
      <div>
        {childrenArray.map((child, index) =>
          visibilityStates[index] ? child : null
        )}
      </div>
    </div>
  );
}
function SelectorButton({ label, shownAtom }) {
  const [isShown, setIsShown] = useAtom(shownAtom || atom(false));

  return (
    <button
      onClick={() => setIsShown((prev) => !prev)}
      className={classNames(
        isShown ? "bg-blue-500 text-white" : "bg-gray-200",
        "rounded-md px-8 py-2 text-center shadow-md"
      )}
    >
      <h2 className="text-lg">{label}</h2>
    </button>
  );
}

function SelectorItem({ children }) {
  return <>{children}</>;
}

// Utility function to simulate auto-save behavior
// function useAutoSave(saveFn, delay = 1000) {
//   const [debouncedSave] = useState(() => {
//     let timeoutId;
//     return (data) => {
//       clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => saveFn(data), delay);
//     };
//   });

//   const handleChange = useCallback(
//     (data) => {
//       debouncedSave(data);
//     },
//     [debouncedSave]
//   );

//   return { handleChange };
// }

function PatientInformationSection({
  noteId,
  patient,
  setIsUpdatingNote,
  showAtom,
}) {
  const [age, setAge] = useState(patient?.age || undefined);
  const [ageType, setAgeType] = useState(patient?.ageType || undefined);
  const [sex, setSex] = useState(patient?.sex || undefined);

  useEffect(() => {
    if (
      age !== patient?.age ||
      ageType !== patient?.ageType ||
      sex !== patient?.sex
    ) {
      // Implement API call to save patient information
      console.log("Saving patient information:", { noteId, age, ageType, sex });
    }
  }, [age, ageType, sex, patient, noteId]);

  return (
    <Section title="Patient Information" showAtom={showAtom} patient={true}>
      <div className="space-y-2">
        {patient && (
          <>
            <div className="whitespace-pre-wrap">
              <p className="pb-1 font-semibold">Sex</p>
              <Select
                value={{
                  value: sex || "MALE",
                  label: toTitleCase(sex || "Male"),
                }}
                options={["MALE", "FEMALE", "OTHER"].map((option) => ({
                  value: option,
                  label: toTitleCase(option),
                }))}
                onChange={(newVal) => {
                  if (newVal) {
                    setSex(newVal.value);
                  }
                }}
              />
            </div>
            <div className="whitespace-pre-wrap">
              <p className="pb-1 font-semibold">Age</p>
              <div className="grid grid-cols-2 items-center gap-4">
                <input
                  type="number"
                  placeholder="Age"
                  className="rounded-md border border-gray-300 py-1.5"
                  value={age}
                  max={125}
                  min={1}
                  pattern="\d*"
                  onChange={(e) => {
                    setAge((v) =>
                      e.target.validity.valid ? e.target.valueAsNumber : v
                    );
                  }}
                />
                <Select
                  value={{
                    value: ageType || "YEARS",
                    label: toTitleCase(ageType || "YEARS"),
                  }}
                  options={["YEARS", "MONTHS", "DAYS"].map((option) => ({
                    value: option,
                    label: toTitleCase(option),
                  }))}
                  onChange={(newVal) => {
                    if (newVal) {
                      setAgeType(newVal.value);
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Section>
  );
}

function NoteDetail() {
  const [isPageFocused, setIsPageFocused] = useState(document.hasFocus());

  const { data, setData, getNoteByID, sessionToken, user } =
    useContext(DataContext);

  let CC = data.results?.summary;
  if (data.results.followup !== null && data.results.followup !== undefined) {
    CC = CC + "\n\n" + data.results.followup;
  }

  const mipsShownAtom = atom(false);
  const heartShownAtom = atom(false);
  const nihssShownAtom = atom(false);
  const pecarnShownAtom = atom(false);

  const patientShownAtom = atom(false);

  const heartPointsResultAtom = atomWithReset({
    HISTORY: undefined,
    EKG: undefined,
    AGE: undefined,
    RISK_FACTORS: undefined,
    INITIAL_TROPONIN: undefined,
  });

  const nihssPointsResultAtom = atomWithReset({
    HISTORY: undefined,
    EKG: undefined,
    AGE: undefined,
    RISK_FACTORS: undefined,
    INITIAL_TROPONIN: undefined,
  });

  const pecarnOptionsResultAtom = atomWithReset({
    Age: undefined,
    "GCS ≤14, palpable skull fracture or signs of AMS": undefined,
    "Occipital, parietal or temporal scalp hematoma; history of LOC ≥5 sec; not acting normally per parent or severe mechanism of injury?":
      undefined,
    "GCS ≤14 or signs of basilar skull fracture or signs of AMS": undefined,
    "History of LOC or history of vomiting or severe headache or severe mechanism of injury":
      undefined,
  });

  const { id } = useParams(); // This will get the value of :id from the URL

  useEffect(() => {
    // window.location.reload();
    getNoteByID(id);
  }, [sessionToken, id, isPageFocused]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isUpdatingNote, setIsUpdatingNote] = useState(false);
  const [macros, setMacros] = useState([]);
  const [isMacrosSuccess, setIsMacrosSuccess] = useState(false);
  const [includeSections, setIncludeSections] = useState();
  // includeSectionsDefault
  const [hasLoadedIncludedSections, setHasLoadedIncludedSections] =
    useState(false);
  const [heartPointsResult, setHeartPointsResult] = useState(0);
  const [nihssPointsResult, setNihssPointsResult] = useState(0);
  // const [pecarnOptionsResult, setPecarnOptionsResult] = useState({});

  const shownNIHSSAtom = atom(true);
  const pointResultNIHSSAtom = atom({});

  const mips = MIPS.mips;

  const [mipsNotes, setMipsNotes] = useState("");

  const handleMipsUpdate = (newContent) => {
    setIsUpdatingNote(true);
    // Simulating an API call
    setTimeout(() => {
      setMipsNotes(newContent);
      setIsUpdatingNote(false);
    }, 5);
  };

  useEffect(() => {
    // Simulating data fetching
    const fetchData = async () => {
      // Fetch note data
      // Fetch macros
      // Fetch user data
      // Update states accordingly
      setIsLoading(false);
      setIsSuccess(true);
      // setData(fetchedData);
      // setMacros(fetchedMacros);
      // setIsMacrosSuccess(true);
      // setUser(fetchedUser);
    };

    fetchData();
  }, []);

  const backButton = (
    <a href="/">
      <ArrowLeftIcon className="inline h-3" /> Go back to dashboard
    </a>
  );

  const handleRetryProcessingConversation = () => {
    // Implement retry logic here
    console.log("Retrying processing conversation...");
  };

  if (
    isLoading ||
    (isSuccess &&
      data?.results.status &&
      data.results.status !== "COMPLETE" &&
      data?.results.status !== "TRANSCRIBING_FOLLOWUP" &&
      data?.results.status !== "SUMMARIZING_FOLLOWUP" &&
      data?.results.status !== "FOLLOW_UP_UPLOAD_STARTED" &&
      data?.results.status !== "FOLLOW_UP_UPLOADED")
  ) {
    const messages = {
      NOTE_CREATED: "Initial note created, to be uploaded",
      NEW_UPLOAD_STARTED: "Initial note - uploading started",
      NEW_UPLOADED: "Initial note - Uploading Completed",
      FOLLOW_UP_UPLOAD_STARTED: "Follow Up - uploading started",
      FOLLOW_UP_UPLOADED: "Follow Up - Uploaded",
      TRANSCRIBING_ORIGINAL: "Transcribing your conversation...",
      TRANSCRIBING_FOLLOWUP: "Transcribing your follow up...",
      SUMMARIZING_ORIGINAL: "Summarizing your conversation...",
      SUMMARIZING_FOLLOWUP: "Summarizing your follow up...",
      COMPLETE: "Your note is ready!",
      ERROR: "There was an error processing your conversation.",
    };

    const isProcessing =
      isLoading ||
      !data?.results.status ||
      data?.results.summary === null ||
      (data?.results.status !== "ERROR" &&
        data?.results.status !== "COMPLETE" &&
        data?.results.status !== "TRANSCRIBING_FOLLOWUP" &&
        data?.results.status !== "SUMMARIZING_FOLLOWUP" &&
        data?.results.status !== "FOLLOW_UP_UPLOAD_STARTED" &&
        data?.results.status !== "FOLLOW_UP_UPLOADED");

    console.log("IS PROCESSING", isProcessing);

    return (
      <Layout>
        <header className="mx-8 mb-7 flex justify-between">{backButton}</header>
        <article className="space-y-4 px-8">
          <section className="flex justify-between">
            {isProcessing ? (
              <div className="flex items-center gap-x-2">
                <div className="h-6 w-10 animate-pulse rounded-lg bg-gray-300" />
                <span className="animate-pulse text-3xl font-medium text-gray-300">
                  &middot;
                </span>
                <div className="h-6 w-28 animate-pulse rounded-lg bg-gray-300" />
                <div className="ml-4 h-9 w-24 animate-pulse rounded-lg bg-gray-300" />
              </div>
            ) : null}
          </section>
          <div className="flex flex-col items-center ">
            {isProcessing ? <Spinner /> : null}
            <span
              className={classNames(
                "pt-4",
                isProcessing || "font-medium text-red-600"
              )}
            >
              {isLoading
                ? "Loading your conversation..."
                : data?.results.status
                ? messages[data.results.status]
                : "Processing your conversation..."}
            </span>
            {!isProcessing &&
            data?.results.status === "ERROR" &&
            data.note.noteSlug ? (
              <button
                className="mt-8 flex items-center gap-2 rounded-md border bg-gray-50 px-4 py-2 shadow-md"
                onClick={handleRetryProcessingConversation}
              >
                <ArrowPathIcon className="h-4" /> Try Again
              </button>
            ) : null}
          </div>
        </article>
      </Layout>
    );
  }

  if (!isSuccess && !isLoading) {
    return (
      <Layout>
        <header className="mx-8 mb-7 flex justify-between">{backButton}</header>
        <article className="space-y-4 px-8">
          <section className="mb-6 flex justify-between">
            <div className="flex items-center">
              <h1 className="text-3xl font-medium">
                Oops! Something went wrong.{" "}
              </h1>
            </div>
            <h2 className="text-3xl text-gray-600">
              Please try reloading or contact us for support!
            </h2>
          </section>
        </article>
      </Layout>
    );
  }

  const { note, results, isBlocked, isReady } = data;

  // const copiableContent = wholeNoteToText(
  //   data.results,
  //   includeSections,
  //   {
  //     calculatorOptions: cdtSectionsData,
  //     pointResults: {
  //       [CDTSections.HEART]: heartPointsResult,
  //       [CDTSections.NIHSS]: nihssPointsResult,
  //     },
  //     scoreRanges,
  //   },
  //   {
  //     calculator: CDTRecursiveSections.PECARN,
  //     optionsResults: pecarnOptionsResult,
  //     calculatorData: cdtRecursiveSectionsData[CDTRecursiveSections.PECARN],
  //   }
  // );

  return (
    <Layout>
      <header className="mx-8 mb-7 flex justify-between">{backButton}</header>

      <article className="space-y-4 px-8">
        <NoteHeader
          note={note}
          isReady={isReady}
          results={results}
          copiableContent={CC}
          isBlocked={isBlocked}
        />

        {!isReady && (
          <div className="flex flex-col items-center justify-center gap-4">
            <Spinner />
            <span>Processing your conversation...</span>
          </div>
        )}
      </article>
      <div>
        {isReady && !results && (
          <div className="mt-10 rounded-md bg-gray-200 p-4">
            <h2 className="text-center text-lg font-medium text-gray-800">
              Oops, something went wrong. Looks like the transcript is empty.
              Did you make sure to record the conversation correctly?
            </h2>
          </div>
        )}

        {isReady && results && isBlocked && (
          <>
            <div className="relative">
              <img
                src="/images/hpi-preview.jpeg"
                className="w-full"
                alt="Note preview"
              />
              <div className="absolute bottom-0 h-96 w-full bg-gradient-to-t from-white"></div>
            </div>
            <div className="mt-10 rounded-md bg-gray-200 p-4">
              <h2 className="text-center text-lg font-medium text-gray-800">
                You've reached your limit of days using Time. Please upgrade to
                a premium plan to continue using Dr. Haydar Time!
              </h2>
            </div>

            <div className="mt-10 flex justify-center">
              <a
                href="/subscription"
                className="rounded-md bg-primary px-4 py-1 text-white hover:bg-primary-600"
              >
                Subscribe Now!
              </a>
            </div>
          </>
        )}

        {isReady && results && !isBlocked && (
          <>
            <div className="flex flex-col-reverse gap-x-4 md:grid md:grid-cols-2">
              <div>
                <PatientInformationSection
                  noteId={note.id}
                  patient={note.patient}
                  setIsUpdatingNote={setIsUpdatingNote}
                  showAtom={patientShownAtom}
                />

                <TextSection
                  noteId={note.id}
                  content={results.summary ? results.summary : ""}
                  isLoading={isUpdatingNote}
                  noteSlug={note.noteSlug}
                  macros={isMacrosSuccess ? macros : []}
                  resultsKey="summary"
                  right={
                    <NoteSummaryMenu
                      onGenerateAgain={handleRetryProcessingConversation}
                    />
                  }
                  sectionProps={{
                    title: "Note",
                    showAtom: results?.summary,
                  }}
                />
              </div>
              <div>
                {results.followup && (
                  <TextSection
                    noteId={note.id}
                    noteSlug={note.noteSlug}
                    content={results?.followup}
                    macros={isMacrosSuccess ? macros : []}
                    resultsKey="followup"
                    right={
                      note.followUpSlug && (
                        <NoteSummaryMenu
                          onGenerateAgain={() =>
                            handleRetryProcessingConversation(note.followUpSlug)
                          }
                        />
                      )
                    }
                    sectionProps={{
                      title: "Follow Up",
                      showAtom: includeSections?.followup,
                    }}
                  />
                )}

                {[
                  "Emergency Medicine",
                  "Emergency Department Provider",
                  "EM Physician",
                ].includes(user?.publicMetadata.profession) && (
                  <Section title="Clinical Decision Tools">
                    <Selector>
                      <SelectorItem title="MIPS" shownAtom={mipsShownAtom}>
                        <MipsSection
                          noteId={note.id}
                          noteSlug={note.noteSlug}
                          content={mipsNotes}
                          isUpdatingNote={isUpdatingNote}
                          mipsData={mips}
                          mipsSuccess={true}
                          resultsKey="mips_notes"
                          onUpdate={handleMipsUpdate}
                        />
                      </SelectorItem>

                      <SelectorItem title="HEART" shownAtom={heartShownAtom}>
                        <CDTCalculator
                          noteId={note.id}
                          calculator={CDTSections.HEART}
                          calculatorOptions={cdtSectionsData[CDTSections.HEART]}
                          pointResultAtom={heartPointsResultAtom} // Make sure this is the atom, not a value
                          scoreRanges={scoreRanges[CDTSections.HEART]}
                          showAtom={heartShownAtom} // Add this prop
                        />
                      </SelectorItem>

                      <SelectorItem title="NIHSS" shownAtom={nihssShownAtom}>
                        <CDTCalculator
                          noteId={note.id}
                          calculator={CDTSections.NIHSS}
                          calculatorOptions={cdtSectionsData[CDTSections.NIHSS]}
                          pointResultAtom={nihssPointsResultAtom}
                          setPointResultAtom={setNihssPointsResult}
                          scoreRanges={scoreRanges[CDTSections.NIHSS]}
                        />
                      </SelectorItem>
                      <SelectorItem title="PECARN" shownAtom={pecarnShownAtom}>
                        <RecursiveCalculator
                          noteId={note.id}
                          calculator={CDTRecursiveSections.PECARN}
                          calculatorData={
                            cdtRecursiveSectionsData[
                              CDTRecursiveSections.PECARN
                            ]
                          }
                          optionsResultsAtom={pecarnOptionsResultAtom}
                          showAtom={pecarnShownAtom}
                        />
                      </SelectorItem>
                    </Selector>
                  </Section>
                )}
              </div>
            </div>

            <NoteFooter
              backButton={backButton}
              copiableContent={CC}
              isBlocked={isBlocked}
            />
          </>
        )}
      </div>

      <Toaster position="bottom-right" reverseOrder={false} />
    </Layout>
  );
}

export default NoteDetail;
