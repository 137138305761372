import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";

import CustomUserButton from "./CustomUserButton";
import { Disclosure } from "@headlessui/react";
import Footer from "./Footer";
//import Image from "next/image";
import { PropsWithChildren } from "react";
//import TrialModal from "./TrialModal";
//import { calculateTrialDaysLeft } from "../utils/lib";
import { classNames } from "../utils/tailwind";
//import { parsedPathNames } from "../utils/lib";
//import { useRouter } from "next/router";
//import { useSubscription } from "use-stripe-subscription";
//import { useUser } from "@clerk/nextjs";
import { useUser } from "@clerk/clerk-react";
//import { useEffect } from "react";
//import Router from "next/router";
import { Link } from "react-router-dom";

const subscriptionsEnabled =
  process.env.NEXT_PUBLIC_SUBSCRIPTIONS_ENABLED === "true";

const pageHeaders = {
  "/": "All notes",
  "/subscriptions": "Subscription",
};

export default function Layout({ children }) {
  //const router = useRouter();
  //const { isLoaded: isUserLoaded, user } = useUser();
  //const { isLoaded: isSubscriptionLoaded, subscription } = useSubscription();
  const isUserLoaded = true;
  const isSubscriptionLoaded = true;
  const subscription = true;

  const { user } = useUser();

  const isLoaded = isUserLoaded && isSubscriptionLoaded;
  const trialDaysLeft = 1; // calculateTrialDaysLeft(user);
  const isAdmin =
    user?.publicMetadata?.isAdmin || user?.publicMetadata?.isSuperAdmin;

  const userNavigation = [
    { name: "All notes", content: "All notes", href: "/", current: false },
    {
      name: "Custom Phrases",
      content: "Custom Phrases",
      href: "/phrase",
      current: false,
    },
  ];

  const adminNavigation = [
    {
      name: "MIAN DE NARRE WAJAN GEE ",
      content: "MIAN DE NARRE WAJAN GEE ",
      href: "/data-model",
      current: false,
    },
  ];

  // desktop navigation updated
  const desktopNavigation = [
    ...userNavigation,
    //  ...(isAdmin ? adminNavigation : []),
  ];

  const mobileNavigation = [
    ...desktopNavigation,
    { name: "Subscriptions", href: "/subscription", current: false },
  ];

  // Only maps over mobileNavigation as it has every item in desktopNavigation
  // for (const item of mobileNavigation)
  //   if (item.href === router.pathname) item.current = true;

  // MIAANN GGG ZINDABADDD

  // useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     if (url && user) {
  //       analytics.page(
  //         (router.pathname in parsedPathNames && parsedPathNames[url]) ||
  //         router.pathname,
  //         {
  //           page: url,
  //           userId: user.id,
  //           email: user.emailAddresses?.[0]?.emailAddress,
  //         },
  //       );
  //     }
  //   };

  //   Router.events.on("routeChangeComplete", handleRouteChange);
  //   return () => {
  //     Router.events.off("routeChangeComplete", handleRouteChange);
  //   };
  // }, []);

  return (
    <div className="relative min-h-screen pb-56">
      <div className="bg-primary-600 pb-32">
        <Disclosure
          as="nav"
          className="border-b border-primary-300 border-opacity-25 bg-primary-600 lg:border-none"
        >
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-primary-400 lg:border-opacity-25">
                  <div className="flex items-center px-2 lg:px-0">
                    <div className="flex-shrink-0">
                      <Link to="/">
                        <img
                          width={120}
                          height={32}
                          src="/images/logo-white.webp"
                          alt="Dr. Haydar Logo"
                        />
                      </Link>
                    </div>
                    <div className="hidden lg:ml-10 lg:block">
                      <div className="flex space-x-4">
                        {desktopNavigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-primary-700 text-white"
                                : "text-white hover:bg-primary-500 hover:bg-opacity-75",
                              "flex items-center rounded-md px-3 py-2 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.content}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-primary-600 p-2 text-primary-200 hover:bg-primary-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>

                  <div className="hidden lg:ml-4 lg:block">
                    <div className="flex items-center">
                      {/* {subscriptionsEnabled && (
                        <Link
                          href="/subscription"
                          className="rounded-md px-3 py-2 text-sm font-medium text-primary-50"
                          aria-current={
                            router.pathname === "/subscription"
                              ? "page"
                              : undefined
                          }
                        >
                          <div className="flex items-center gap-x-2">
                            <span>Subscription</span>
                            {isLoaded &&
                              !isAdmin &&
                              !subscription &&
                              trialDaysLeft !== null &&
                              trialDaysLeft > 0 && (
                                <div className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-2.5 py-1.5 text-xs font-semibold text-primary-600 shadow-sm">
                                  <InformationCircleIcon
                                    className="-ml-0.5 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  {trialDaysLeft} trial{" "}
                                  {trialDaysLeft === 1 ? "day" : "days"} left
                                </div>
                              )}
                            {isLoaded &&
                              !isAdmin &&
                              !subscription &&
                              trialDaysLeft !== null &&
                              trialDaysLeft <= 0 && (
                                <div className="inline-flex items-center gap-x-1 rounded-full bg-white px-2 py-1 text-xs font-semibold text-primary-600 shadow-sm">
                                  <ExclamationCircleIcon
                                    className="-ml-0.5 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  Trial expired
                                </div>
                              )}
                          </div>
                        </Link>
                      )} */}

                      {/* Profile dropdown */}
                      <div className="px-4">
                        <CustomUserButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2">
                  {mobileNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-primary-700 text-white"
                          : "text-white hover:bg-primary-500 hover:bg-opacity-75",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-primary-700 px-5 pb-4">
                  <CustomUserButton showName />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <header className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">
              {/* {pageHeaders[router.pathname as keyof typeof pageHeaders]} */}
            </h1>
          </div>
        </header>
      </div>

      <main className="-mt-32 mb-24">
        <div className="mx-auto w-[90%] max-w-[96rem] rounded-md bg-white px-4 pb-12 sm:px-6 lg:px-8">
          <div className="min-h-96 rounded-md py-12">{children}</div>
        </div>
      </main>

      <Footer />

      {/* {router.pathname !== "/subscription" && <TrialModal />} */}
    </div>
  );
}
